import React from "react"
import Section from "./section"
import SectionHorizontal from "./sectionHorizontal"

const Sections = ({ sectionsContent, horizontalLayout }) => {
  if (horizontalLayout) {
    return (
      <section className="tiles tiles-horizontal">
        <div className="row">
          {sectionsContent.map((section, index) =>
            <div className="col col-one-third" key={index}>
              <SectionHorizontal subtitle={section.subtitle} title={section.title} caption={section.caption} button_text={section.button_text} url={section.url} external_link={section.external_link} image={section.image.childImageSharp.fluid} />
            </div>
          )}
        </div>
      </section>
    );
  } else {
    return (
      <section className="tiles">
        <div className="container">
          {sectionsContent.map((section, index) =>
            <Section subtitle={section.subtitle} title={section.title} caption={section.caption} button_text={section.button_text} url={section.url} external_link={section.external_link} image={section.image.childImageSharp.fluid} key={index} />
          )}
        </div>
      </section>
    );
  }
}

export default Sections
