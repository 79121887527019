import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"
import Sections from "../components/sections"

const ServicesPage = ({ data, pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;

  const title = data.markdownRemark.frontmatter.title;
  const caption = data.markdownRemark.frontmatter.caption;
  const sectionsContent = data.markdownRemark.frontmatter.sections;
  const body = data.markdownRemark.html;

  return (
    <Layout navSolid={true} progressEnabled={true}>
      <SEO title={title} />
      <Content title={title} crumbs={crumbs} caption={caption} body={body} centered={true} />
      <Sections sectionsContent={sectionsContent} horizontalLayout={true} />
    </Layout>
  )
}

export const query = graphql`
query {
  markdownRemark(frontmatter: { template_key: { eq: "services" } }) {
    frontmatter {
      title
      caption
      sections {
        subtitle
        title
        caption
        button_text
        url
        external_link
        image {
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    html
  }
}
`

export default ServicesPage
