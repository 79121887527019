import React from "react"
import { Link } from "gatsby"
import { Fade } from "react-awesome-reveal";
import Img from "gatsby-image"

const Section = ({ subtitle, title, caption, button_text, url, external_link, image }) => {

  const CustomLink = () => {
    if (external_link) {
      return (
        <a href={url} className="button btn-bordered">
          {button_text} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><polygon points="9.3,1.3 7.9,2.7 12.2,7 0,7 0,9 12.2,9 7.9,13.3 9.3,14.7 16,8 "></polygon></svg>
        </a>
      )
    } else {
      return (
        <Link to={url} className="button btn-bordered">
          {button_text} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><polygon points="9.3,1.3 7.9,2.7 12.2,7 0,7 0,9 12.2,9 7.9,13.3 9.3,14.7 16,8 "></polygon></svg>
        </Link>
      )
    }
  }

  return (
    <div className="tile">
      <div className="row no-gutters">
        <div className="col">
          <div className="content-wrapper">
            <Fade triggerOnce>
              <p className="subtitle">{subtitle}</p>
              <h3>{title}</h3>
              <p>{caption}</p>
              <CustomLink />
            </Fade>
          </div>
        </div>
        <div className="col">
          <Fade triggerOnce>
            <Img className="tile-image" fluid={image} />
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default Section
