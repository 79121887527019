import React from "react"
import { Link } from "gatsby"
import { Fade } from "react-awesome-reveal";
import Img from "gatsby-image"

const SectionHorizontal = ({ subtitle, title, caption, button_text, url, external_link, image }) => {

  const Section = () => {
    if (external_link) {
      return (
        <a href={url}>
          <div className="tile">
            <Fade triggerOnce>
              <Img className="tile-image" fluid={image} />
            </Fade>
            <div className="content-wrapper">
              <Fade triggerOnce>
                <p className="subtitle">{subtitle}</p>
                <h3>{title}</h3>
                <p>{caption}</p>
                <div className="button btn-text">
                  {button_text} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><polygon points="9.3,1.3 7.9,2.7 12.2,7 0,7 0,9 12.2,9 7.9,13.3 9.3,14.7 16,8 "></polygon></svg>
                </div>
              </Fade>
            </div>
          </div>
        </a>
      )
    } else {
      return (
        <Link to={url}>
          <div className="tile">
            <Fade triggerOnce>
              <Img className="tile-image" fluid={image} />
            </Fade>
            <div className="content-wrapper">
              <Fade triggerOnce>
                <p className="subtitle">{subtitle}</p>
                <h3>{title}</h3>
                <p>{caption}</p>
                <div className="button btn-text">
                  {button_text} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><polygon points="9.3,1.3 7.9,2.7 12.2,7 0,7 0,9 12.2,9 7.9,13.3 9.3,14.7 16,8 "></polygon></svg>
                </div>
              </Fade>
            </div>
          </div>
        </Link>
      )
    }
  }

  return (
    <Section />
  )
}

export default SectionHorizontal
